import { getBase64EncodedLegupFamilyJson } from '@utils';

import {
  fetchFamilyStatus,
  fetchCenterDirectory,
  dependentAgeGroup,
  ageGroupProgramSchedules,
  updateOrRemoveWaitlistSpot,
  passOrDeclineSeatOffer,
  bookTour,
  cancelTour,
  fetchToursForParent,
  fetchUpcomingParentTourForCenter,
  tourProviderSetting,
  centerTourPolicy,
  centerTourSlots,
  providerWaitlistFees,
  validateProviderWaitlistCoupon,
  validateSubsidiesDiscount,
  checkForProviderQuestionsDiscount,
  checkForProviderSubsidiesDiscount,
  findCenterInfo,
  findCenterQuestions,
  findCenterSubsidies,
  checkForProviderCoupons,
  rescheduleTour,
  fetchSeatOfferDetails,
  fetchSeatOffersForParent,
  sendOffersMessage,
  requestPaperwork,
  updateWaitlistSpotAnswers,
  acceptSeatOffer,
} from './legup.service';

export default {
  namespaced: true,
  state: {
    toursForParent: [],
    waitlistsForParent: [],
    centerDirectory: {},
    centerId: null,
    parentId: null,
    centersForWaitlists: [],
    upcomingTour: {},
    pastTour: {},
    dependentAgeGroup: null,
    tourPolicy: {},
    tourSlots: {},
    waitlistFees: {},
    couponInfo: {},
    centerInfo: {},
    centerQuestions: [],
    centerSubsidies: [],
    providerCoupons: [],
    seatOfferDetails: {},
    seatOffersForParent: [],
    seatOfferMessageParams: {},
    declineOfferInfo: {},
    tourEnabled: false,
    subsidyDiscount: false,
  },
  mutations: {
    setToursForParent(state, payload) {
      state.toursForParent = payload;
    },
    setWaitlistsForParent(state, payload) {
      state.waitlistsForParent = payload;
    },
    setCenterDirectory(state, payload) {
      state.centerDirectory = payload;
    },
    setCenterId(state, payload) {
      state.centerId = payload;
    },
    setParentId(state, payload) {
      state.parentId = payload;
    },
    setCentersForWaitlists(state, payload) {
      state.centersForWaitlists = payload;
    },
    setDeclineOfferInfo(state, payload) {
      state.declineOfferInfo = payload;
    },
    setDependentAgeGroup: (state, { ageGroupId }) => {
      state.dependentAgeGroup = ageGroupId;
    },
    setCenterParentUpcomingTour: (state, { tour }) => {
      state.upcomingTour = tour;
    },
    setCenterParentPastTour: (state, { tour }) => {
      state.pastTour = tour;
    },
    setTourSetting: (state, { tourEnabled }) => {
      state.tourEnabled = tourEnabled;
    },
    setSubsidyDiscount: (state, { subsidyDiscount }) => {
      state.subsidyDiscount = subsidyDiscount;
    },
    setTourPolicy: (state, { tourPolicy }) => {
      state.tourPolicy = tourPolicy;
    },
    setTourSlots: (state, { tourSlots }) => {
      state.tourSlots = tourSlots;
    },
    setWaitlistFees: (state, { waitlistFees }) => {
      state.waitlistFees = waitlistFees;
    },
    setCouponInfo: (state, { couponInfo }) => {
      state.couponInfo = couponInfo;
    },
    setCenterInfo: (state, { centerInfo }) => {
      state.centerInfo = centerInfo;
    },
    setCenterQuestions: (state, { centerQuestions }) => {
      state.centerQuestions = centerQuestions;
    },
    setCenterSubsidies: (state, { centerSubsidies }) => {
      state.centerSubsidies = centerSubsidies;
    },
    setProviderCoupons: (state, { providerCoupons }) => {
      state.providerCoupons = providerCoupons;
    },
    setSeatOfferDetails: (state, { seatOfferDetails }) => {
      state.seatOfferDetails = seatOfferDetails;
    },
    setSeatOffersForParent: (state, { seatOffersForParent }) => {
      state.seatOffersForParent = seatOffersForParent;
    },
    setSeatOfferMessageParams: (state, { seatOfferMessageParams }) => {
      state.seatOfferMessageParams = seatOfferMessageParams;
    },
  },
  getters: {
    parentHasWaitlists(state) {
      return state.waitlistsForParent.length > 0;
    },
    isParentOnWaitlistForCenter(state, getters) {
      if (!state.centerId) return false;
      return !!getters.waitlistForCenter;
    },
    waitlistForCenter(state) {
      if (!state.centerId) return null;
      const waitlists = state.waitlistsForParent
        .filter((waitlist) => parseInt(waitlist.centerId, 10) === state.centerId);
      return waitlists.length > 0 && waitlists[0];
    },
    centerHasOpenSeats(state) {
      return state.centerDirectory.seats && state.centerDirectory.seats.length > 0;
    },
    centerHasWaitlistEnabled(state) {
      return !!state.centerDirectory.waitlistUrl;
    },
    centerHasToursEnabled(state) {
      return state.centerDirectory.offerTours && state.centerDirectory.offerTours.length > 0;
    },
    centerHasWaitlistToursEnabled(state, getters) {
      return getters.centerHasToursEnabled && state.centerDirectory.offerTours.includes('waitlist');
    },
    manageWaitlistUrl(state) {
      return `/manage_legup_waitlist/${state.parentId}`;
    },
    contactUrl(state, getters) {
      const { directoryUrl } = state.centerDirectory;
      return `${directoryUrl}?contact=open&f=${getters.familiesParam}`;
    },
    tourUrl(state, getters) {
      const { directoryUrl } = state.centerDirectory;
      return `${directoryUrl}?t=open&f=${getters.familiesParam}`;
    },
    openSpotsUrl(state, getters) {
      const { directoryUrl } = state.centerDirectory;
      return `${directoryUrl}?tab=seats&f=${getters.familiesParam}`;
    },
    familiesParam(state, getters, rootState, rootGetters) {
      return getBase64EncodedLegupFamilyJson(
        rootState.user.current,
        rootGetters['dependents/asArray'] || [],
      );
    },
    centerFromWaitlists: (state) => (centerId) => (
      state.centersForWaitlists.filter((center) => center.centerId === centerId)[0]
    ),
    upcomingTour(state) {
      return state.upcomingTour;
    },
    pastTour(state) {
      return state.pastTour;
    },
    dependentAgeGroup(state) {
      return state.dependentAgeGroup;
    },
    ageGroupProgramSchedules(state) {
      return state.ageGroupProgramSchedules;
    },
    updateOrRemoveWaitlistSpot(state) {
      return state.updateOrRemoveWaitlistSpot;
    },
    passOrDeclineSeatOffer(state) {
      return state.passOrDeclineSeatOffer;
    },
    requestPaperwork(state) {
      return state.requestPaperwork;
    },
    tourProviderSetting(state) {
      return state.tourEnabled;
    },
    validateSubsidiesDiscount(state) {
      return state.subsidyDiscount;
    },
    centerTourPolicy(state) {
      return state.tourPolicy;
    },
    centerTourSlots(state) {
      return state.tourSlots;
    },
    providerWaitlistFees(state) {
      return state.waitlistFees;
    },
    validateProviderWaitlistCoupon(state) {
      return state.couponInfo;
    },
    checkForProviderQuestionsDiscount(state) {
      return state.couponInfo;
    },
    checkForProviderSubsidiesDiscount(state) {
      return state.couponInfo;
    },
    findCenterInfo(state) {
      return state.centerInfo;
    },
    findCenterQuestions(state) {
      return state.centerQuestions;
    },
    findCenterSubsidies(state) {
      return state.centerSubsidies;
    },
    checkForProviderCoupons(state) {
      return state.providerCoupons;
    },
    fetchSeatOfferDetails(state) {
      return state.seatOfferDetails;
    },
    getCenterSeatOfferDetails(state) {
      return state.centerSeatOfferDetails;
    },
    getDeclineOfferInfo(state) {
      return state.declineOfferInfo;
    },
  },
  actions: {
    async fetchFamilyStatus({
      commit,
      dispatch,
      rootState,
    }, email) {
      try {
        const response = await fetchFamilyStatus(email);
        if (response?.success) {
          await commit('setWaitlistsForParent', response.waitlists);
          await commit('setCentersForWaitlists', response.centers);

          // if there is at least 1 waitlist, get the parent id from the waitlist object
          // if there is more than 1 parent in the parents array on the waitlist object,
          // get the id for the matching kinside parent email
          if (response.waitlists.length > 0) {
            const kinsideParentEmail = rootState.user.current.email;
            let legupParentId = null;
            response.waitlists[0].parents.forEach((parent) => {
              if (parent.email === kinsideParentEmail) {
                legupParentId = parent.parentId;
              }
            });
            if (legupParentId) {
              await commit('setParentId', legupParentId);
            }
          }
        }
      } catch (error) {
        dispatch(
          'notifications/addToastError',
          { text: 'Something went wrong fetching parent from LegUp', error },
          { root: true },
        );
      }
    },
    async fetchCenterDirectory({ commit, dispatch }, centerId) {
      try {
        const response = await fetchCenterDirectory(centerId);
        if (response?.success) {
          commit('setCenterDirectory', response.centers[centerId]);
          commit('setCenterId', centerId);
        }
      } catch (error) {
        dispatch(
          'notifications/addToastError',
          { text: 'Something went wrong fetching provider from LegUp', error },
          { root: true },
        );
      }
    },
    async dependentAgeGroup({ commit }, { centerId, childBirthDate }) {
      const response = await dependentAgeGroup(centerId, childBirthDate);
      if (response?.success) {
        commit('setDependentAgeGroup', { ageGroupId: Number(response.ageGroupId) });
      } else {
        commit('setDependentAgeGroup', { ageGroupId: undefined });
      }
    },

    async ageGroupProgramSchedules({ dispatch }, params) {
      try {
        const programs = await ageGroupProgramSchedules(params);

        return programs;
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch age group program schedules', error }, { root: true });
        throw new Error(`ageGroupProgramSchedules failed due to ${error.code}: ${error.message}`);
      }
    },

    async updateOrRemoveWaitlistSpot({ dispatch }, params) {
      try {
        const result = await updateOrRemoveWaitlistSpot(params);
        if (params.remove_from_waitlist && params.provider_removal) {
          dispatch(
            'notifications/addToastSuccess',
            { text: 'Waitlist spot successfully removed.' },
            { root: true },
          );
        }
        return result;
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not update the waitlist spot. Refresh the page to try again, or contact concierge@kinside.com for more help.', error }, { root: true });
        throw new Error();
      }
    },

    async passOrDeclineSeatOffer({ dispatch }, params) {
      try {
        await passOrDeclineSeatOffer(params);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not complete this offer response. Refresh the page to try again, or contact concierge@kinside.com for more help.', error }, { root: true });
        throw new Error(`passOrDeclineSeatOffer failed due to: ${error.code}: ${error.message}`);
      }
    },

    async bookTour({ commit, dispatch }, {
      centerId, parent, child, tourInfo, noFamilyNotes, ageGroupId,
    }) {
      try {
        const response = await bookTour(
          centerId,
          parent,
          child,
          tourInfo,
          noFamilyNotes,
          ageGroupId,
        );
        if (response?.success) {
          commit('setCenterParentUpcomingTour', { tour: response.tour });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not book tour', error }, { root: true });
        throw new Error();
      }
    },
    async cancelTour({ commit, dispatch }, { tour }) {
      try {
        const response = await cancelTour(tour);
        if (response?.success) {
          commit('setCenterParentUpcomingTour', { tour: null });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not cancel tour', error }, { root: true });
        throw new Error();
      }
    },
    async rescheduleTour({ commit, dispatch }, { tour, newDateTime }) {
      try {
        const response = await rescheduleTour(tour, newDateTime);
        if (response?.success) {
          commit('setCenterParentUpcomingTour', { tour: response.tour });
          dispatch('notifications/addToastSuccess', { text: 'Tour is rescheduled successfully' }, { root: true });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not reschedule tour', error }, { root: true });
        throw new Error();
      }
    },
    async centerParentTour({ rootState, dispatch, commit }, { legupCenterId }) {
      try {
        if (rootState.user.current.legupParentId) {
          const response = await fetchUpcomingParentTourForCenter(
            rootState.user.current.legupParentId,
            legupCenterId,
          );
          if (response?.success) {
            const today = new Date();
            const upcomingTours = response.tours.filter((t) => (
              new Date(new Date(t.startTime).toDateString()) >= today
            ));
            upcomingTours.sort((a, b) => a.startTime.localeCompare(b.startTime));
            commit('setCenterParentUpcomingTour', { tour: (upcomingTours.shift() || {}) });

            const pastTours = response.tours.filter((t) => (
              new Date(new Date(t.startTime).toDateString()) <= today
            ));
            pastTours.sort((a, b) => a.startTime.localeCompare(b.startTime));
            commit('setCenterParentPastTour', { tour: (pastTours.shift() || {}) });
          }
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch legup tours data', error }, { root: true });
      }
    },
    async fetchToursForParent({ rootState, dispatch, commit }) {
      try {
        if (rootState.user.current.legupParentId) {
          const response = await fetchToursForParent(
            rootState.user.current.legupParentId,
          );
          if (response?.success) {
            commit('setToursForParent', response.tours);
          }
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch all tours for parent', error }, { root: true });
      }
    },

    async tourProviderSetting({ commit, dispatch }, { params }) {
      try {
        const response = await tourProviderSetting(params);
        if (response?.success) {
          commit('setTourSetting', { tourEnabled: response.tourEnabled });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not retrieve tour setting', error }, { root: true });
      }
    },
    async validateSubsidiesDiscount({ commit, dispatch }, centerId) {
      try {
        const response = await validateSubsidiesDiscount(centerId);
        if (response?.success) {
          commit('setSubsidyDiscount', { subsidyDiscount: response.subsidy_discount });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'There is no subsidy discount.', error }, { root: true });
      }
    },
    async centerTourPolicy({ commit, dispatch }, { centerId }) {
      try {
        const response = await centerTourPolicy(centerId);
        if (response?.success) {
          const { policy, virtualInstructions } = response;
          commit('setTourPolicy', { tourPolicy: { policy, virtualInstructions } });
        }
      } catch (error) {
        dispatch(
          'notifications/addToastError',
          { text: 'Something went wrong fetching facility tour policy', error },
          { root: true },
        );
      }
    },
    async centerTourSlots({ commit, dispatch }, { params }) {
      try {
        const response = await centerTourSlots(params);
        if (response?.success) {
          commit('setTourSlots', { tourSlots: response.times });
        }
      } catch (error) {
        dispatch(
          'notifications/addToastError',
          { text: 'Something went wrong fetching facility available tours slots', error },
          { root: true },
        );
      }
    },
    async providerWaitlistFees({ commit, dispatch }, params) {
      try {
        const response = await providerWaitlistFees(params);
        if (response?.success) {
          commit('setWaitlistFees', { waitlistFees: response.waitlist_fee });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not retrieve the waitlist fees', error }, { root: true });
      }
    },
    async validateProviderWaitlistCoupon({ commit, dispatch }, params) {
      try {
        const response = await validateProviderWaitlistCoupon(params);
        if (response?.success) {
          commit('setCouponInfo', { couponInfo: response });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not validate the provider coupon', error }, { root: true });
      }
    },
    async checkForProviderQuestionsDiscount({ commit, dispatch }, params) {
      try {
        const response = await checkForProviderQuestionsDiscount(params);
        if (response?.success) {
          commit('setCouponInfo', { couponInfo: response });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not retrieve the provider questions discount', error }, { root: true });
      }
    },
    async checkForProviderSubsidiesDiscount({ commit, dispatch }, params) {
      try {
        const response = await checkForProviderSubsidiesDiscount(params);
        if (response?.success) {
          commit('setCouponInfo', { couponInfo: response });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not retrieve the provider subsidies discount', error }, { root: true });
      }
    },
    async findCenterInfo({ commit, dispatch }, params) {
      try {
        const response = await findCenterInfo(params);
        commit('setCenterInfo', { centerInfo: response });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not retrieve the LegUp center information', error }, { root: true });
      }
    },
    async findCenterQuestions({ commit, dispatch }, params) {
      try {
        const response = await findCenterQuestions(params);
        commit('setCenterQuestions', { centerQuestions: response });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not retrieve the center questions', error }, { root: true });
      }
    },
    async findCenterSubsidies({ commit, dispatch }, params) {
      try {
        const response = await findCenterSubsidies(params);
        commit('setCenterSubsidies', { centerSubsidies: response });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not retrieve the center subsidies', error }, { root: true });
      }
    },
    async checkForProviderCoupons({ commit, dispatch }, params) {
      try {
        const response = await checkForProviderCoupons(params);
        commit('setProviderCoupons', { providerCoupons: response });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not retrieve provider coupons', error }, { root: true });
      }
    },
    async fetchSeatOfferDetails({ commit, dispatch }, params) {
      try {
        const response = await fetchSeatOfferDetails(params);
        commit('setSeatOfferDetails', { seatOfferDetails: response });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not retrieve offer details', error }, { root: true });
      }
    },
    async fetchSeatOffersForParent({ commit, dispatch }, params) {
      try {
        const response = await fetchSeatOffersForParent(params);
        commit('setSeatOffersForParent', { seatOffersForParent: response });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not retrieve offers', error }, { root: true });
      }
    },
    async sendOffersMessage({ commit, dispatch }, seatId) {
      try {
        const response = await sendOffersMessage(seatId);
        commit('setSeatOfferMessageParams', { seatOfferMessageParams: response });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Cannot message this provider', error }, { root: true });
      }
    },
    async requestPaperwork({ dispatch }, seatId) {
      try {
        await requestPaperwork(seatId);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Request was not successful. Refresh the page to try again, or contact concierge@kinside.com for more help.', error }, { root: true });
        throw new Error();
      }
    },
    async updateWaitlistSpotAnswers({ dispatch }, { spotId, answers }) {
      try {
        await updateWaitlistSpotAnswers(spotId, answers);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not update the waitlist spot answers', error }, { root: true });
      }
    },
    async acceptSeatOffer({ dispatch }, { seatId, paymentRequestMethods }) {
      try {
        await acceptSeatOffer({ seatId, paymentRequestMethods });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Request was not successful. Refresh the page to try again, or contact concierge@kinside.com for more help.', error }, { root: true });
        throw error;
      }
    },
    addDeclineOfferInfo({ commit }, declineInfo) {
      commit('setDeclineOfferInfo', declineInfo);
    },
  },
};
